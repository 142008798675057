'use client'
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"
import { ArrowRight, CheckCircle } from 'lucide-react'

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

const steps = [
    "Sign up for an account",
    "Connect your Solana wallet",
    "Set up custom alerts and tracking preferences",
    "Monitor real-time data from our dashboard",
    "Receive instant notifications on important events",
    "Make informed trading decisions"
]

export default function HowItWorks() {
    return (
        <section className="py-20 bg-black text-white">
            <div className="container mx-auto px-4">
                <motion.h2 
                    className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    How It Works
                </motion.h2>
                <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    {steps.map((step, index) => (
                        <div key={index} className="flex items-start">
                            <CheckCircle className="w-6 h-6 text-green-400 mr-3 flex-shrink-0" />
                            <p className="text-gray-300">{step}</p>
                        </div>
                    ))}
                </motion.div>
                <motion.div 
                    className="text-center"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    <Button className="text-white group bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700">
                        Get Started Now
                        <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                    </Button>
                </motion.div>
            </div>
        </section>
    )
}


'use client'
import DexScreenerBoost from "@/components/client/main-page/DexScreenerBoost"
import FeaturesOverview from "@/components/client/main-page/FeaturesOverview"
import Home from "@/components/client/main-page/Home"
import HowItWorks from "@/components/client/main-page/HowItWorks"
import LandingPage from "@/components/client/main-page/LandingPage"
import NewsScraper from "@/components/client/main-page/NewsScraper"
import SolanaWalletTracker from "@/components/client/main-page/SolanaWalletTracker"
import TwitterScraper from "@/components/client/main-page/TwitterScrapper"
import { useRouter } from 'next/navigation'

export default function Main() {
    const router = useRouter()

    return (
        <div className="">
            <Home />
            <LandingPage />
            <FeaturesOverview />
            <SolanaWalletTracker />
            <DexScreenerBoost />
            <NewsScraper />
            <TwitterScraper />
            <HowItWorks />
        </div>
    )
}


'use client'
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"
import { ArrowRight, Twitter } from 'lucide-react'

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

export default function TwitterScraper() {
    return (
        <section className="py-20 bg-black text-white">
            <div className="container mx-auto px-4">
                <motion.div 
                    className="grid grid-cols-1 lg:grid-cols-2 gap-12"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    <div className="lg:order-2">
                        <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400">TwitterTerminal</h2>
                        <p className="text-gray-300 mb-6">
                            Stay ahead of the curve with our TwitterTerminal. Monitor influential accounts, 
                            track trending topics, and spot potential moonshots before they take off.
                        </p>
                        <ul className="list-disc list-inside text-gray-300 mb-8 space-y-2">
                            <li>Real-time tweet monitoring</li>
                            <li>Sentiment analysis of crypto discussions</li>
                            <li>Trending hashtag tracking</li>
                            <li>Customizable alerts for specific keywords or accounts</li>
                        </ul>
                        <Button className="text-white group bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700">
                            Start Monitoring
                            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                        </Button>
                    </div>
                    <div className="lg:order-1">
                        <div className="bg-white/[0.02] backdrop-blur-sm border border-white/5 p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4">
                                <Twitter className="w-6 h-6 text-cyan-400 mr-2" />
                                <span className="font-semibold">Trending Solana Tweets</span>
                            </div>
                            <div className="space-y-4">
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">@SolanaWhale</p>
                                    <p className="text-xs text-gray-400">Just spotted a new memecoin with 10x potential! $SOLMEME</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">@CryptoInfluencer</p>
                                    <p className="text-xs text-gray-400">Solana ecosystem is on fire! TVL up 20% this week alone 🚀</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">@DeFiDeveloper</p>
                                    <p className="text-xs text-gray-400">New Solana DEX launching tomorrow. Get ready for the airdrop!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}


'use client'
import { motion } from "framer-motion"
import { Wallet, TrendingUp, Newspaper, Twitter } from 'lucide-react'

const features = [
    {
        title: "Solana Wallet Tracker",
        description: "Monitor any Solana wallet in real-time, tracking transactions and token movements.",
        icon: <Wallet className="w-6 h-6 text-purple-400" />
    },
    {
        title: "DexScreener Boost",
        description: "Enhanced DexScreener functionality for faster and more efficient trading insights.",
        icon: <TrendingUp className="w-6 h-6 text-green-400" />
    },
    {
        title: "News Terminal",
        description: "Stay updated with the latest Solana and memecoin news from multiple sources.",
        icon: <Newspaper className="w-6 h-6 text-blue-400" />
    },
    {
        title: "TwitterTerminal",
        description: "Track influential accounts and spot trending coins before they take off.",
        icon: <Twitter className="w-6 h-6 text-cyan-400" />
    }
]

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

export default function FeaturesOverview() {
    return (
        <section className="py-20 bg-black text-white">
            <div className="container mx-auto px-4">
                <motion.h2 
                    className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    Powerful Features for Solana Traders
                </motion.h2>
                <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    {features.map((feature, index) => (
                        <div key={index} className="p-6 rounded-xl bg-white/[0.02] backdrop-blur-sm border border-white/5 hover:bg-white/[0.04] transition-colors">
                            <div className="flex items-center gap-2 mb-4">
                                {feature.icon}
                                <h3 className="text-xl font-semibold">{feature.title}</h3>
                            </div>
                            <p className="text-gray-400">{feature.description}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
        </section>
    )
}


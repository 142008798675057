import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/providers/client-walet-provider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/app/components/providers/posthog-provider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/components/providers/theme-provider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/toaster.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/lib/auth/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/IBMPlexSans-Medium.ttf\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/IBMPlexSans-Regular.ttf\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");

'use client'

import { Bell, Search, FileText, ChevronDown, Menu, LogOut, Settings, Sparkles } from "lucide-react"
import { Button } from "@/components/ui/button"
import { useUser } from "@/lib/auth"
import {
    Sheet,
    SheetContent,
    SheetTrigger,
} from "@/components/ui/sheet"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import { signOut } from "@/app/(client)/(login)/actions"
import { useRouter } from 'next/navigation'
import Link from "next/link"

export default function Navbar() {
    const { user, setUser } = useUser();
    const router = useRouter();

    async function handleSignOut() {
        setUser(null);
        await signOut();
        router.push('/');
    }

    return (
        <nav className="fixed top-0 left-0 right-0 z-50 border-b bg-gradient-to-r backdrop-blur-xl ">
            <div className="flex h-16 items-center px-4 md:px-6">
                {/* Mobile Menu */}
                <Sheet>
                    <SheetTrigger asChild>
                        <Button variant="ghost" size="icon" className="md:hidden">
                            <Menu className="h-5 w-5" />
                            <span className="sr-only">Toggle menu</span>
                        </Button>
                    </SheetTrigger>
                </Sheet>

                {/* Logo */}
                <Link href='/' className="flex items-center gap-2 ml-4 md:ml-0">
                    <div className="relative w-8 h-8">
                        <div className="absolute inset-0 bg-gradient-to-tr from-purple-500 to-pink-500 rounded-lg transform rotate-3"></div>
                        <div className="absolute inset-0 bg-background rounded-lg flex items-center justify-center">
                            <div className="w-4 h-4 border-2 border-purple-500 rounded-sm transform rotate-45"></div>
                        </div>
                    </div>
                    <span className="text-lg font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400 bg-clip-text text-transparent">
                        Solana Trending
                    </span>
                </Link>

                <div className="flex items-center gap-4 ml-auto">
                    {user ? (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                                    <Avatar className="h-8 w-8 rounded-lg">
                                        <AvatarImage src={user?.avatar} alt={user?.name} />
                                        <AvatarFallback className="rounded-lg">
                                            {user?.name
                                                ?.split(" ")
                                                .map((name) => name.charAt(0))
                                                .join("")
                                                .toUpperCase()}
                                        </AvatarFallback>
                                    </Avatar>
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56" align="end" forceMount>
                                <DropdownMenuLabel className="font-normal">
                                    <div className="flex flex-col space-y-1">
                                        <p className="text-sm font-medium leading-none">{user?.name}</p>
                                        <p className="text-xs leading-none text-muted-foreground">
                                            {user?.email}
                                        </p>
                                    </div>
                                </DropdownMenuLabel>
                                {/* <DropdownMenuGroup>
                                    <DropdownMenuItem asChild>
                                        <Link href="/pricing" className="flex items-center cursor-pointer w-full">
                                            <Sparkles className="mr-2 h-4 w-4" />
                                            <span>Upgrade to Pro</span>
                                        </Link>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup> */}
                                {/* dashboard */}
                                <DropdownMenuGroup>
                                    <DropdownMenuItem asChild>
                                        <Link href="/dashboard" className="flex items-center cursor-pointer w-full">
                                            <FileText className="mr-2 h-4 w-4" />
                                            <span>Dashboard</span>
                                        </Link>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem asChild>
                                        <Link href="/dashboard/settings" className="flex items-center cursor-pointer w-full">
                                            <Settings className="mr-2 h-4 w-4" />
                                            <span>Settings</span>
                                        </Link>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem onClick={handleSignOut} className="cursor-pointer">
                                    <LogOut className="mr-2 h-4 w-4" />
                                    <span>Sign out</span>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    ) : (
                        <div className="flex items-center gap-2">
                            <Link href="/sign-in">
                                <Button variant="ghost">Sign In</Button>
                            </Link>
                            <Link href="/sign-up">
                                <Button className="bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white">
                                    Get Started
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    )
}
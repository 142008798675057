'use client'
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"
import { ArrowRight, Newspaper } from 'lucide-react'

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

export default function NewsScraper() {
    return (
        <section className="py-20 bg-black text-white">
            <div className="container mx-auto px-4">
                <motion.div 
                    className="grid grid-cols-1 lg:grid-cols-2 gap-12"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    <div>
                        <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400">News Terminal</h2>
                        <p className="text-gray-300 mb-6">
                            Stay informed with our powerful News Terminal. Get the latest updates on Solana, 
                            memecoins, and the broader crypto market from multiple trusted sources.
                        </p>
                        <ul className="list-disc list-inside text-gray-300 mb-8 space-y-2">
                            <li>Real-time news aggregation</li>
                            <li>Customizable news feeds</li>
                            <li>Sentiment analysis</li>
                            <li>Breaking news alerts</li>
                        </ul>
                        <Button className="text-white group bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700">
                            Access News Feed
                            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                        </Button>
                    </div>
                    <div>
                        <div className="bg-white/[0.02] backdrop-blur-sm border border-white/5 p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4">
                                <Newspaper className="w-6 h-6 text-blue-400 mr-2" />
                                <span className="font-semibold">Latest Solana News</span>
                            </div>
                            <div className="space-y-4">
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">Solana TVL Reaches New All-Time High</p>
                                    <p className="text-xs text-gray-400">CryptoNews - 10 minutes ago</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">New Memecoin PEPE Launches on Solana</p>
                                    <p className="text-xs text-gray-400">MemeWatch - 1 hour ago</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm font-semibold">Solana Foundation Announces Developer Grants</p>
                                    <p className="text-xs text-gray-400">BlockchainInsider - 3 hours ago</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}


'use client'

import { motion, AnimatePresence } from "framer-motion"
import { Button } from "@/components/ui/button"
import Link from "next/link"
import { ArrowRight, Rocket, ChartBar, Sparkles, Zap, Timer, TrendingUp, Wallet, Twitter, Newspaper, Bell } from "lucide-react"
import { useEffect, useState } from "react"

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

const stagger = {
    animate: {
        transition: {
            staggerChildren: 0.2
        }
    }
}

export default function LandingPage() {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 2000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <div className="relative min-h-screen bg-transparent overflow-hidden">
            {/* Animated Background Elements */}
            <div className="absolute inset-0">
                <div className="absolute top-0 -left-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-screen filter blur-xl opacity-5 animate-blob"></div>
                <div className="absolute top-0 -right-4 w-72 h-72 bg-cyan-500 rounded-full mix-blend-screen filter blur-xl opacity-5 animate-blob animation-delay-2000"></div>
                <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-500 rounded-full mix-blend-screen filter blur-xl opacity-5 animate-blob animation-delay-4000"></div>
            </div>

            {/* Grid Pattern */}
            <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zNiAzNGM0LjQxOCAwIDgtMy41ODIgOC04cy0zLjU4Mi04LTgtOC04IDMuNTgyLTggOCAzLjU4MiA4IDggOHoiIHN0cm9rZT0icmdiYSgyNTUsIDI1NSwgMjU1LCAwLjAyKSIvPjwvZz48L3N2Zz4=')] opacity-20"></div>

            <AnimatePresence>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="relative z-10 min-h-screen flex flex-col justify-between bg-transparent"
                >
                    {/* Hero Section */}
                    <div className="container mx-auto px-4 pt-20">
                        <motion.div
                            initial="initial"
                            animate="animate"
                            variants={stagger}
                            className="text-center relative z-10 max-w-5xl mx-auto p-4 "
                        >
                            <motion.div variants={fadeIn} className="mb-8">
                                <span className="inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-gradient-to-r from-purple-500/5 to-purple-500/10 border border-purple-500/20 text-purple-300 shadow-[0_0_15px_rgba(168,85,247,0.15)] backdrop-blur-sm">
                                    <Rocket className="w-4 h-4 mr-2" />
                                    CA: 
                                </span>
                            </motion.div>

                            <motion.h1
                                variants={fadeIn}
                                className="text-5xl md:text-4xl lg:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400 leading-tight"
                            >
                                Track Any Solana Wallet
                            </motion.h1>

                            {/* <motion.p
                                    variants={fadeIn}
                                    className="text-xl text-gray-400 max-w-2xl mx-auto"
                                >
                                    Monitor any Solana wallet in real-time. Track transactions, token movements,
                                    and trading activities with instant notifications.
                                </motion.p> */}
                        </motion.div>
                    </div>

                    {/* Bottom Section with Stats and Button */}
                    <div className="container mx-auto px-4 pb-32">
                        {/* Stats Section */}
                        <motion.div variants={fadeIn} className="flex flex-col sm:flex-row gap-4 justify-center items-center mb-10">
                            <Link href="/sign-up" passHref>
                                <Button className="group relative px-8 py-6 bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 rounded-xl text-lg font-medium shadow-[0_0_25px_rgba(168,85,247,0.5)] w-full sm:w-auto overflow-hidden">
                                    <motion.div
                                        className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
                                        initial={{ x: "-100%" }}
                                        whileHover={{ x: "100%" }}
                                        transition={{ duration: 0.5 }}
                                    />
                                    <span className="relative flex items-center justify-center gap-2 text-white">
                                        <span>Get Started</span>
                                        <Rocket className="w-5 h-5" />
                                    </span>
                                </Button>
                            </Link>
                        </motion.div>
                        <motion.div
                            variants={fadeIn}
                            className="grid grid-cols-2 md:grid-cols-4 gap-6 max-w-4xl mx-auto "
                        >
                            {[
                                {
                                    label: "Real-time Wallet Tracking",
                                    value: "Instant",
                                    icon: <Wallet className="w-5 h-5 text-purple-400" />
                                },
                                {
                                    label: "Twitter Feeds Monitored",
                                    value: "100+",
                                    icon: <Twitter className="w-5 h-5 text-blue-400" />
                                },
                                {
                                    label: "News Sources",
                                    value: "50+",
                                    icon: <Newspaper className="w-5 h-5 text-cyan-400" />
                                },
                                {
                                    label: "Alert Delivery",
                                    value: "<1s",
                                    icon: <Bell className="w-5 h-5 text-green-400" />
                                }
                            ].map((stat, index) => (
                                <div key={index} className="p-4 rounded-xl bg-white/[0.02] backdrop-blur-sm border border-white/5 hover:bg-white/[0.04] transition-colors">
                                    <div className="flex items-center justify-center gap-2 text-2xl font-bold mb-1">
                                        {stat.icon}
                                        {stat.value}
                                    </div>
                                    <div className="text-gray-400 text-sm text-center">{stat.label}</div>
                                </div>
                            ))}
                        </motion.div>

                        {/* Button Section */}

                    </div>
                </motion.div>

            </AnimatePresence>
        </div>
    )
}
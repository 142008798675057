'use client'
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"
import { ArrowRight, Wallet } from 'lucide-react'
import Link from "next/link"

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
}

export default function SolanaWalletTracker() {
    return (
        <section className="py-20 bg-black text-white">
            <div className="container mx-auto px-4">
                <motion.div 
                    className="grid grid-cols-1 lg:grid-cols-2 gap-12"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    <div>
                        <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-cyan-400">Solana Wallet Tracker</h2>
                        <p className="text-gray-300 mb-6">
                            Our advanced Solana Wallet Tracker allows you to monitor any Solana wallet in real-time. 
                            Track transactions, token movements, and trading activities with instant notifications.
                        </p>
                        <ul className="list-disc list-inside text-gray-300 mb-8 space-y-2">
                            <li>Real-time transaction monitoring</li>
                            <li>Token balance updates</li>
                            <li>Historical transaction analysis</li>
                            <li>Customizable alerts for specific activities</li>
                        </ul>
                        <Link href="/sign-up" className="text-white group bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 border border-transparent px-6 py-3 rounded-lg inline-flex items-center font-semibold transition-colors">
                            Try Wallet Tracker
                            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
                        </Link>
                    </div>
                    <div>
                        <div className="bg-white/[0.02] backdrop-blur-sm border border-white/5 p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4">
                                <Wallet className="w-6 h-6 text-purple-400 mr-2" />
                                <span className="font-semibold">Wallet: Gm3aU...X1pkR</span>
                            </div>
                            <div className="space-y-4">
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm text-gray-300">Incoming transfer: 100 SOL</p>
                                    <p className="text-xs text-gray-400">2 minutes ago</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm text-gray-300">Token swap: 50 USDC → 0.5 SOL</p>
                                    <p className="text-xs text-gray-400">15 minutes ago</p>
                                </div>
                                <div className="bg-white/[0.05] p-3 rounded">
                                    <p className="text-sm text-gray-300">NFT purchase: DeGods #1234</p>
                                    <p className="text-xs text-gray-400">1 hour ago</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

